<template>
  <div class="register-container">
    <h2>注册</h2>
    <form @submit.prevent="register" class="register-form">
      <input v-model="username" type="text" placeholder="用户名" required>
      <input v-model="password" type="password" placeholder="密码" required>
      <input v-model="confirmPassword" type="password" placeholder="确认密码" required>
      <button type="submit">注册</button>
    </form>
  </div>
</template>

<script>
import api from '@/services/api'

export default {
  name: 'Register',
  data() {
    return {
      username: '',
      password: '',
      confirmPassword: '',
      discordId: this.$route.query.discordId
    }
  },
  methods: {
    async register() {
      if (this.password !== this.confirmPassword) {
        alert('密码不匹配');
        return;
      }
      try {
        const response = await api.post('/auth/register', {
          username: this.username,
          password: this.password,
          discordId: this.discordId
        });
        console.log('Registration response:', response.data);
        alert('注册成功，请登录');
        this.$router.push('/login');
      } catch (error) {
        console.error('Registration error:', error);
        alert(error.response?.data?.message || '注册失败');
      }
    }
  }
}
</script>

<style scoped>
.register-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f0f2f5;
}

.register-form {
  display: flex;
  flex-direction: column;
  width: 300px;
  padding: 20px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

input {
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

button {
  padding: 10px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

button:hover {
  background-color: #45a049;
}
</style>
