<template>
  <div>
    <h2>授权成功</h2>
    <p>正在跳转到仪表板...</p>
  </div>
</template>

<script>
export default {
  name: 'AuthSuccess',
  created() {
    const token = this.$route.query.token;
    if (token) {
      localStorage.setItem('token', token);
      this.$router.push('/dashboard');
    } else {
      this.$router.push('/login');
    }
  }
}
</script>
