<template>
  <div class="discord-auth-initiate">
    <h1>猫屋多人联机酒馆 Discord 授权登录</h1>
    <button @click="initiateAuth" class="auth-button">授权</button>
  </div>
</template>

<script>
import api from '@/services/api'

export default {
  name: 'DiscordAuthInitiate',
  methods: {
    async initiateAuth() {
      try {
        const response = await api.get('/auth/discord');
        if (typeof response.data.url === 'string') {
          console.log('Redirecting to:', response.data.url); // 添加日志
          window.location.href = response.data.url;
        } else {
          console.error('Invalid Discord auth URL:', response.data);
          alert('获取Discord授权URL时出错，请稍后重试。');
        }
      } catch (error) {
        console.error('Error initiating Discord auth:', error);
        alert('启动Discord授权时出错，请稍后重试。');
      }
    }
  }
}
</script>

<style scoped>
.discord-auth-initiate {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.auth-button {
  padding: 10px 20px;
  font-size: 16px;
  background-color: #7289DA;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
</style>
