<template>
  <div>
    <h2>授权失败</h2>
    <p>{{ errorMessage }}</p>
    <button @click="goToHome">返回首页</button>
  </div>
</template>

<script>
export default {
  name: 'AuthFailed',
  props: ['reason', 'message'],
  data() {
    return {
      errorMessage: ''
    }
  },
  created() {
    if (this.reason === 'no_role') {
      this.errorMessage = '您没有所需的Discord身份组权限。';
    } else if (this.message) {
      this.errorMessage = this.message;
    } else {
      this.errorMessage = '授权过程中发生错误。';
    }
    console.log('Auth failed reason:', this.reason);
    console.log('Auth failed message:', this.message);
  },
  methods: {
    goToHome() {
      this.$router.push('/auth/discord');
    }
  }
}
</script>
