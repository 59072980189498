<template>
  <div class="discord-callback">
    <h2>处理 Discord 授权中...</h2>
    <p v-if="error" class="error">{{ error }}</p>
  </div>
</template>

<script>
import api from '@/services/api'

export default {
  name: 'DiscordCallback',
  data() {
    return {
      error: null
    }
  },
  async created() {
    const code = this.$route.query.code;
    if (code) {
      try {
        console.log('Received code:', code);
        const response = await api.get(`/auth/discord/callback?code=${code}`);
        console.log('Response from server:', response.data);

        if (response.data.redirectTo) {
          console.log('Redirecting to:', response.data.redirectTo, 'with data:', response.data);
          switch (response.data.redirectTo) {
            case '/register':
              this.$router.push({
                path: '/register',
                query: { discordId: response.data.discordId }
              });
              break;
            case '/auth-success':
              this.$router.push({
                path: '/auth-success',
                query: { token: response.data.token }
              });
              break;
            case '/auth-failed':
              this.$router.push({
                path: '/auth-failed',
                query: {
                  reason: response.data.reason,
                  message: response.data.message
                }
              });
              break;
            default:
              this.$router.push(response.data.redirectTo);
          }
        } else {
          this.error = '服务器响应中缺少重定向信息';
          console.error('Server response:', response.data);
        }
      } catch (error) {
        console.error('Error handling Discord callback:', error);
        console.error('Error response:', error.response);
        this.error = error.response?.data?.message || '处理 Discord 授权时出错';
      }
    } else {
      this.error = '未收到授权码';
      console.error('No authorization code received');
      setTimeout(() => this.$router.push('/auth/discord'), 3000);
    }
  }
}
</script>

<style scoped>
.discord-callback {
  text-align: center;
  padding: 20px;
}

.error {
  color: red;
  margin-top: 20px;
}
</style>
