import { createRouter, createWebHistory } from 'vue-router'
import Login from '@/components/Login.vue'
import Register from '@/components/Register.vue'
import Dashboard from '@/components/Dashboard.vue'
import DiscordAuthInitiate from '@/views/DiscordAuthInitiate.vue'
import AuthSuccess from '@/views/AuthSuccess.vue'
import AuthFailed from '@/views/AuthFailed.vue'
import DiscordAuth from '@/components/DiscordAuth.vue'

const routes = [
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/register',
    name: 'Register',
    component: Register
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    meta: { requiresAuth: true }
  },
  {
    path: '/auth/discord',
    name: 'DiscordAuthInitiate',
    component: DiscordAuthInitiate
  },
  {
    path: '/auth/discord/callback',
    name: 'DiscordAuth',
    component: DiscordAuth
  },
  {
    path: '/auth-success',
    name: 'AuthSuccess',
    component: AuthSuccess,
    props: (route) => ({ token: route.query.token })
  },
  {
    path: '/auth-failed',
    name: 'AuthFailed',
    component: AuthFailed,
    props: (route) => ({ reason: route.query.reason, message: route.query.message })
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!localStorage.getItem('token')) {
      next({
        path: '/login',
        query: { redirect: to.fullPath }
      })
    } else {
      next()
    }
  } else {
    next()
  }
})

export default router
